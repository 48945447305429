<template>
  <div id="settleDone"></div>
</template>

<script>
import { componentState } from './index.js'

export default {
  name: 'settle-next-index',
  setup() {
    return { ...componentState() }
  }
}
</script>

<style scoped></style>
